.content {
  &-inner {
    @media screen and (max-width: 800px) {
      width: 92%;
      margin: 0 auto;
    }

    &.o-middle {
      @media screen and (min-width: 801px) {
        max-width: 960px;
        margin: 0 auto;
      }
    }

    &.o-full_-s {
      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
  }
}