
.logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @media screen and (max-width: 800px) {
    width: 20px;
  }

  @media screen and (min-width: 801px) {
    width: 40px;
  }

  img {
    width: 100%;
  }
}