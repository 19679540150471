.works_nav {
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  top: 20%;
  right: 30px;
  z-index: 100;

  @media screen and (max-width: 800px) {
    display: none;
  }

  li {
    margin: 15px;
  }

  .dot {
    width: 8px;
    height: 8px;
    overflow: hidden;
    display: block;
    text-indent: 100%;
    background-color: #666666;
    border-radius: 50vw;
    cursor: pointer;
    transition: 0.5s;

    &:hover, &:focus, &.current {
      background-color: #ffffff;
    }

    &:hover, &:focus {
      transform: scale(1.3);
    }
  }
}