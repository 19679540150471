$marginNum: calc(47.5 / 1440 * 100%);

.photos {
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 801px) {
    margin: $marginNum auto;
  }
}

.photo {
  visibility: hidden;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 30%;
    margin-top: 5%;
    margin-left: 5%;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 801px) {
    width: calc(250 / 1440 * 100%);
    margin-top: $marginNum;
    margin-left: $marginNum;

    &:nth-child(5n + 1) {
      margin-left: 0;
    }
  }

  &-image {
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
      filter: grayscale(1);
      transition: filter 0.5s, transform 0.3s;
    }

    &:hover, &:focus {
      img {
        filter: grayscale(0);
        transform: scale(1.1);
      }
    }
  }
}