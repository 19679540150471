.works-wrapper {
  @media screen and (max-width: 800px) {
    margin: 50vh 0 0;
  }

  @media screen and (min-width: 801px) {
    margin: 50vh 0 0 calc(150 / 1200 * 100%);
  }
}

/*
.pin-spacer {
  &:nth-child(odd) {
    background-color: #111;
  }

  &:nth-child(even) {
    background-color: #333;
  }
}
*/

.work {
  margin: 100px 0;
  height: 50vh;

  &-inner {
    opacity: 0.3;

    @media screen and (max-width: 800px) {
      margin-bottom: 3em;
    }

    @media screen and (min-width: 801px) {
      display: flex;
    }
  }

  &-image {
    @media screen and (max-width: 800px) {
      width: 60%;
    }

    @media screen and (min-width: 801px) {
      width: 25%;
    }

    figure {
      @media screen and (min-width: 801px) {
        perspective: 1000px;
      }
    }

    img {
      width: 100%;
      height: auto;
      filter: grayscale(1);
    }
  }

  &-text_content {
    display: flex;
    align-items: center;
    overflow: hidden;
    clip-path: inset(0% 100% 0% 0%);

    @media screen and (min-width: 801px) {
      width: calc(75% - 30px);
      margin-left: 30px;
    }

    > .inner {
      width: 100%;
    }
  }

  &-title {
    padding-bottom: 0.8em;
    color: #b3b3b3;
    font-size: 1.9rem;
    letter-spacing: 0.2em;
    border-bottom: solid 1px #545454;

    @media screen and (min-width: 801px) {
      margin-top: 0;
    }
  }

  &-url {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.2em;

    a {
      width: fit-content;
      text-decoration: none;
      display: block;
      background-image: linear-gradient(to right, #b3b3b3 0%, #b3b3b3 0%, #808080 0%);
      -webkit-background-clip: text;
      color: transparent;
    }
  }
}