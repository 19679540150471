body {
  color: #ffffff;
  background: #000;
}

a {
  color: #ffffff;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
}