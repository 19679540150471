/* メインビジュアル */
.main-visual {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  position: relative;
  background-image: linear-gradient(to right bottom, #bf77d2, #3477ae);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &-inner {
    width: 1000px;
    margin: 100px auto;
    position: absolute;
    visibility: hidden;
    opacity: 0;

    &::before {
      content: '';
      width: 100%;
      padding-top: 20%;
      display: block;
    }

    &.pointer {
      cursor: pointer;
    }

    img {
      vertical-align: bottom;
    }
  }

  .types {
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;

    &, &-shadow {
      position: absolute;
      left: 0;
    }

    &-shadow {
      left: 1%;
      bottom: 5%;
      filter: blur(6px);

      img {
        width: 100%;
        height: auto;
        transform-origin: left bottom;
        transform: skewX(-60deg) scaleY(30%);
      }
    }
  }

  .type {
    height: 100%;
    position: relative;
    box-sizing: content-box;
    perspective: 120px;
    // border: solid 1px red;

    [class*="tp"] {
      position: absolute;

      img {
        width: 100%;
        height: auto;
      }
    }

    // シ
    &-shi01,
    &-shi02 {
      width: 8.9155%;

      .tp01,
      .tp02 {
        width: calc(34.9054 / 89.155 * 100%);
        left: 0;

        img {
          vertical-align: top;
        }
      }

      .tp01 {
        top: 0;
      }

      .tp02 {
        top: calc(44.5774 / 200 * 100%);
      }

      .tp03 {
        width: calc(22.2887 / 89.155 * 100%);
        bottom: 0;
        right: 0;

        img {
          vertical-align: bottom;
        }
      }
    }

    // ン
    &-n {
      width: 7.80106%;
      margin-left: 3.28401%;

      .tp01 {
        width: calc(34.9054 / 78.0106 * 100%);
        top: 0;
        left: 0;

        img {
          vertical-align: top;
        }
      }

      .tp02 {
        width: calc(22.2887 / 78.0106 * 100%);
        bottom: 0;
        right: 0;

        img {
          vertical-align: bottom;
        }
      }
    }

    // セ
    &-se {
      width: 8.91547%;
      margin-left: 4.53183%;

      .tp01 {
        width: 100%;
        left: 0;
        top: calc(26.0035 / 200 * 100%);

        img {
          vertical-align: top;
        }
      }

      .tp02 {
        width: calc(22.2887 / 89.1547 * 100%);
        top: 0;
        left: calc(22.2887 / 89.1547 * 100%);

        img {
          vertical-align: top;
        }
      }

      .tp03 {
        width: calc(55.7217 / 89.1547 * 100%);
        left: calc(22.2887 / 89.1547 * 100%);
        bottom: 0;

        img {
          vertical-align: bottom;
        }
      }
    }

    // イ
    &-i {
      width: 9.658437%;
      margin-left: 3.19427%;

      .tp01 {
        width: 100%;
        top: calc(26.0035 / 200 * 100%);
        left: 0;

        img {
          vertical-align: top;
        }
      }

      .tp02 {
        width: calc(22.2887 / 96.5843 * 100%);
        top: calc(26.0035 / 200 * 100%);
        left: calc(48.2922 / 96.5843 * 100%);

        img {
          vertical-align: bottom;
        }
      }
    }

    // サ
    &-sa {
      width: 11.14435%;
      margin-left: 3.04594%;

      .tp01 {
        width: 100%;
        top: calc(26.0035 / 200 * 100%);
        left: 0;

        img {
          vertical-align: top;
        }
      }

      .tp02 {
        width: calc(22.2887 / 111.4435 * 100%);
        top: 0;
        left: calc(22.2887 / 111.4435 * 100%);

        img {
          vertical-align: top;
        }
      }

      .tp03 {
        width: calc(22.2887 / 111.4435 * 100%);
        top: 0;
        left: calc(66.8663 / 111.4435 * 100%);

        img {
          vertical-align: top;
        }
      }
    }

    // ク
    &-ku {
      width: 8.91547%;
      margin-left: 3.12006%;

      .tp01 {
        width: calc(22.2887 / 89.1547 * 100%);
        top: calc(26.0035 / 200 * 100%);
        left: 0;

        img {
          vertical-align: top;
        }
      }

      .tp02 {
        width: 100%;
        top: calc(26.0035 / 200 * 100%);

        img {
          vertical-align: top;
        }
      }

      .tp03 {
        width: calc(22.2887 / 89.1547 * 100%);
        bottom: 0;
        left: calc(66.8661 / 89.1547 * 100%);

        img {
          vertical-align: bottom;
        }
      }
    }

    // シ
    &-shi02 {
      margin-left: 4.23492%;
    }

    // ツ
    &-tsu {
      width: 11.14434%;
      margin-left: 3.19435%;

      .tp01,
      .tp02 {
        width: calc(34.9054 / 111.4434 * 100%);
        left: 0;

        img {
          vertical-align: top;
        }
      }

      .tp01 {
        top: 0;
      }

      .tp02 {
        left: calc(44.5773 / 111.4434 * 100%);
      }

      .tp03 {
        width: calc(22.2887 / 111.4434 * 100%);
        bottom: 0;
        right: 0;

        img {
          vertical-align: bottom;
        }
      }
    }
  }

  .bullet, .y_line, .x_line {
    will-change: transform, opacity, filter;
  }

  .types-shadow img {
    will-change: transform, opacity, filter;
  }
}