.scroll_icon {
  width: 1px;
  height: 50px;
  margin: 20vh auto;
  background-color: #999999;
  position: relative;

  @media screen and (max-width: 800px) {
    transform: scale(0.8);
    transform-origin: center center;
  }

  &::before {
    content: '';
    width: 9px;
    height: 9px;
    background-color: #aaaaaa;
    display: block;
    border-radius: 50vw;
    position: absolute;
    left: -4px;
    top: -4px;
    animation: scrollIconAnim 3s infinite;
  }
}

@keyframes scrollIconAnim {
  0% {
    top: -4px;
  }
  70% {
    top: 50px;
  }
  100% {
    top: -4px;
  }
}