.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%) scale(0.7);

  svg {
    transform: rotate(-90deg);
  }

  circle {
    fill: transparent;
    stroke: white;
    stroke-width: 6px;
  }

  .circle_bg_light01 {
    opacity: 0.4;
    filter: blur(3px);
    stroke-width: 8px;
  }

  .circle_bg_light02{
    opacity: 0.4;
    stroke-width: 6px;
  }

  .circle_line {
    // filter: blur(3px);
    stroke-width: 6px;
    stroke-dasharray: 157px;
    stroke-dashoffset: -157px;
    animation: loading_anim 1s ease-in-out infinite;
  }
}

@keyframes loading_anim {
  from {
    stroke-dashoffset: 157px;
  }

  to {
    stroke-dashoffset: -157px;
  }
}