.information {
  margin: 0;
  padding: 0.5em 0.75em;
  font-size: 1.4rem;
  list-style: none;
  position: fixed;
  bottom: 0;
  background-color: rgba(#060606, 0.4);

  @media screen and (max-width: 800px) {
    width: 100%;
  }
}