.global_nav {
  width: 80%;
  max-width: 500px;
  height: 100vh;
  height: 100dvh; // iphone用
  padding: 20px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9000;
  background-color: rgba(#060606, 0.859);
  box-sizing: border-box;
  display: none;
  backdrop-filter: blur(12px);

  ul {
    list-style: none;
    margin: 30% auto 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
      width: 100%;
      padding-left: 0.4em;
      margin: 40px auto;
      text-align: center;
      align-self: center;
      font-family: 'Lato', sans-serif;
      font-style: italic;

      @media screen and (max-width: 800px) {
        font-size: 1.8rem;
      }

      @media screen and (min-width: 801px) {
        font-size: 2.4rem;
      }

      a {
        text-decoration: none;
        letter-spacing: 0.4em;
        opacity: 0.6;
        transition: 0.3s ease-in-out;

        &:hover, &:focus {
          opacity: 1;
          letter-spacing: 0.8em;
        }
      }
    }
  }

  .menu_on & {
    display: block;
  }
}

.menu_icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: fixed;
  z-index: 9001;

  @media screen and (max-width: 800px) {
    top: 0;
    right: 0;
  }

  @media screen and (min-width: 801px) {
    top: 25px;
    right: 25px;
  }

  &::before, &::after {
    content: "";
    width: 40px;
    height: 1px;
    display: block;
    background-color: #fff;
    position: absolute;
    left: 5px;
    transform-origin: center;
    transition: transform 0.3s;
  }

  &::before {
    top: 21px;
    // transform: translateX(-50%);
  }

  &::after {
    top: 28px;
    // transform: translateX(-50%);
  }

  @media (hover: hover) {
    &:hover, &:focus {
      &::before {
        transform: translateX(-5px);
      }

      &::after {
        transform: translateX(5px);
      }
    }
  }

  .menu_on & {
    &::before {
      transform: rotate(-30deg);
    }

    &::after {
      transform: rotate(30deg);
    }
  }
}